export default class Animate {
  constructor(os) {
    this.condominium_type='';
    this._OS = os;
    
  }
  showFaceFrame(){
    document.querySelectorAll('.face-frame')[0].classList.add('show');
  }
  hideFaceFrame(){
    document.querySelectorAll('.face-frame')[0].classList.remove('show');
  }
  showShutter(){
    document.querySelectorAll('.shutter')[0].classList.add('show');
  }
  hideShutter() {
    document.querySelectorAll('.shutter')[0].classList.remove('show');
  }
  startConversation() {
    document.getElementById('call-button-hint').innerHTML = 'Toque para cancelar';
    document.getElementById('button-record').classList.remove('hidden');
    document.getElementById('button-record').classList.add('rotate');
    
  }
  showInstructions() {
    document.getElementById('instructions').classList.remove('hidden');
  }

  startCollectingData() {
    document.getElementById('volume').style.display = 'block';
    document.querySelector('.info-container').classList.add('show');
    
  }

  showProblemForm() {
    document.querySelector('.report-problem-modal').classList.add('show');
  }
  hideProblemForm() {
    document.querySelector('.report-problem-modal').classList.remove('show');
  }

  stopConversation() {
    const video = document.getElementById('user-video');
    video.classList.remove('movedToTop');

    document.getElementById('call-button-hint').innerHTML = '';
    const textFields = document.querySelectorAll('.text-field');

    for (var i = 0; i < textFields.length; i++) {
      textFields[i].classList.remove('show');
    }

    var elements = document.getElementsByClassName('value');
    for (var i = 0; i < elements.length; i++) {
      elements[i].innerHTML = '';
    }

    document.getElementById('button-record').classList.remove('rotate');
    document.getElementById('button-record').classList.add('hidden');
    document.getElementById('user-info').style.display = 'none';
    document.querySelector('.info-container').classList.remove('show');


    this.showQuestion('');
    this.hideLoading();
  }
  showLocationPermissionDenied() {  
    document.getElementById('geolocation-prompt-android').classList.remove('show');
    document.getElementById('geolocation-prompt-ios').classList.remove('show');
    document.getElementById('geolocation-error').classList.add('show');
  }

  async showGeolocationPrompt() {
    if (this._OS =="Android")  {
      document.getElementById("geolocation-prompt-android").classList.add('show');
      navigator.geolocation.getCurrentPosition((res)=>{
        console.log(res);
        this.hideLocationInstructions();
        this.hideGeolocationPrompt();
        this.showMainForm();
        
      }, (err)=>{console.log(err);this.showLocationPermissionDenied();resolve(false)});
    }else if (this._OS =="iOS") {
      document.getElementById("geolocation-prompt-ios").classList.add('show');
    }else{
      console.log("OS nao suportado:fallback android");
      document.getElementById("geolocation-prompt-android").classList.add('show');
      navigator.geolocation.getCurrentPosition((res)=>{
        console.log(res);
        this.hideLocationInstructions();
        this.hideGeolocationPrompt();
        this.showMainForm();
        
      }, (err)=>{console.log(err);this.showLocationPermissionDenied();resolve(false)});
    }
  }

  hideGeolocationPrompt() {
    document.getElementById('geolocation-prompt-android').classList.remove('show');
    document.getElementById('geolocation-prompt-ios').classList.remove('show');
  }

  showMainForm() {
    document.getElementById('volume').style.display = 'none';
    const mainForm = document.getElementById('main-form');
    document.getElementById("visitor-name-input").addEventListener("focus", (event) => {
      this.resetFormValidation();
    });
    document.getElementById("resident-name-input").addEventListener("focus", (event) => {
      this.resetFormValidation();
    });
    mainForm.classList.add('show');
  }

  hideMainForm() {
    document.getElementById('main-form').classList.remove('show');
  }

  showLocationInstructions() {
    document.getElementById('location-instructions-accept').addEventListener('click', () => {
      this.hideLocationInstructions();
      this.showGeolocationPrompt();
    });
    document.getElementById('geolocation-instructions').classList.add('show');
  }

  hideLocationInstructions() {
    document.getElementById('geolocation-instructions').classList.remove('show');
  }
  resetFormValidation(){
    document.getElementById('visitor-name-input').classList.remove('error');
    document.getElementById('visitor-name-error').classList.remove('show');
    document.getElementById('resident-name-input').classList.remove('error');
    document.getElementById('resident-name-error').classList.remove('show');
    document.getElementById('resident-not-found-error').classList.remove('show');

  }

  showMissingVisitorNameError(){
    document.getElementById('visitor-name-input').classList.add('error');
    document.getElementById('visitor-name-error').classList.add('show');
  }
  showMissingResidentNameError(){
    document.getElementById('resident-name-input').classList.add('error');
    document.getElementById('resident-name-error').classList.add('show');
  }
  showResidentNotFoundError(){
    document.getElementById('resident-name-input').classList.add('error');
    document.getElementById('resident-not-found-error').classList.add('show');
  }

  showLoading() {
    document.querySelector('.loader-wrap').classList.add('show');
  }

  hidePids() {
    const pids = [...document.querySelectorAll('.pid')];

    for (const pid of pids) {
      pid.style.backgroundColor = 'transparent';
    }
  }

  hideLoading() {
    document.querySelector('.loader-wrap').classList.remove('show');
  }

  showVideoCall() {
    this.hideLoading();

    document.querySelector('.call-container').classList.add('show');

    const callButtons = document.querySelector('.call-interations');
    callButtons.classList.add('hidden');

    const endCallButton = document.querySelector('.end-call');
    endCallButton.classList.add('show');
  }

  showAccessDenied() {
    this.hideLoading();
    const feedback = document.getElementById('feedback-denied');
    feedback.classList.add('show');
    document.querySelectorAll('.granted')[0].classList.remove('show');
    document.querySelectorAll('.granted')[1].classList.remove('show');
    document.querySelectorAll('.granted')[2].classList.remove('show');
    document.querySelectorAll('.denied')[0].classList.add('show');
    document.querySelectorAll('.denied')[1].classList.add('show');
    document.querySelectorAll('.denied')[2].classList.add('show');
  }

  setupSlider(sliderId, action){
    var inputRange = document.getElementById(sliderId),
    maxValue = 150, // the higher the smoother when dragging
    speed = 12, // thanks to @pixelass for this
    currValue, rafID;

// set min/max value
inputRange.min = 0;
inputRange.max = maxValue;

// listen for unlock
function unlockStartHandler() {
    // clear raf if trying again
    window.cancelAnimationFrame(rafID);
    
    // set to desired value
    currValue = +this.value;
}

function unlockEndHandler() {
    
    // store current value
    currValue = +this.value;
    
    // determine if we have reached success or not
    if(currValue >= maxValue) {
        successHandler();
    }
    else {
        rafID = window.requestAnimationFrame(animateHandler);
    }
}

// handle range animation
function animateHandler() {
    
    // update input range
    inputRange.value = currValue;
    
    // determine if we need to continue
    if(currValue > -1) {
    	window.requestAnimationFrame(animateHandler);   
    }
    
    // decrement value
    currValue = currValue - speed;
}

// handle successful unlock
async function successHandler() {
   await action();
};

// bind events
inputRange.addEventListener('mousedown', unlockStartHandler, false);
inputRange.addEventListener('mousestart', unlockStartHandler, false);
inputRange.addEventListener('mouseup', unlockEndHandler, false);
inputRange.addEventListener('touchend', unlockEndHandler, false);
  }

 async showOpenLoading() {
    console.log('showOpenLoading');
    const myImage = new Image();
    myImage.src = 'image/unlock.gif?'+new Date().getTime();
    document.querySelector('.slider-loading').appendChild(myImage);
    
    document.querySelector('.slider-loading').classList.add('show');
    document.getElementById('keys-container').innerHTML = '';
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        document.querySelector('.slider-loading').innerHTML='';
        resolve("anything");
      }, 5000);
    });
  }

  hideOpenLoading() {
    
    console.log('hideOpenLoading',window.accesses.length);
    document.querySelector('.slider-loading').classList.remove('show');
    if(window.accesses.length==0){
      document.getElementsByClassName('access-counter')[0].classList.add('hidden');
      document.querySelector('.feedback').classList.remove('show');
    }
  }

  showKey(accesses){
    document.getElementById('keys-container').innerHTML = 
    ` <div class="key" id="container${accesses[0].id}">
    <p class="access-name">${accesses[0].description}</p>
    <div class="slider-container">
      <div class="slider-background">
        <img class="arrow" src="image/arrow.png" alt="">
        <div class="unlock"><img src="image/unlock.png" alt=""></div>
        <input type="range" value="0" class="pulee" id="${accesses[0].id}" />
      </div>
    </div>
  </div>`;
  this.setupSlider(accesses[0].id , async function(){
   await window.openAccessKey(accesses[0]);
  });

  }

  updateAccessesCounter(){
    var access_counter = document.getElementsByClassName('access-counter')[0];
    access_counter.innerHTML = (window.current_access_index+1)+"/"+window.accesses_length;
  }

  showAccessGranted() {
    this.hideLoading();
    this.showQuestion('');
    // var access_counter = document.getElementsByClassName('access-counter')[0];
    // access_counter.innerHTML = "1/"+accesses.length;
    // this.showKey(accesses);

    const feedback = document.getElementById('feedback');
    feedback.classList.add('show');
  }
  showQuestion(question) {
    var text = "";
    switch (question) {
      case "REQUEST_VISITOR_NAME":
        text = "Qual o seu nome?";
        break;
      case "REQUEST_VISITOR_DOCUMENT":
        text = "Qual o seu CPF válido?";
        break;
      case "REQUEST_APARTMENT_NUMBER":
       switch (this.condominium_type) {
        case 'horizontal':
          text = "Qual o número da casa?";
          break;
        case 'vertical':
          text = "Qual o número do apartamento?";
          break;
        case 'comercial':
          text = "Qual o número da sala?";
          break;
        default:
          text = "Qual o número do apartamento?";
          break;
       }
        break;
      case "REQUEST_BLOCK":
        text = "Qual o bloco?";
        break;
      case "REQUEST_RESIDENT_NAME":
        text = "Qual o nome da pessoa que você está procurando?";
        break;
      default:
        text = question;
        break;
    }
    document.getElementById('question').innerHTML = text;
  }

  showFeedback(feedback_message) {
    document.querySelector('.start-call').classList.add('hidden');

    document.querySelector('#feedback-message').innerHTML = feedback_message;
    const fb = document.querySelector('.call-feedback');
    fb.classList.add('show');
    this.hideLoading();
  }


  setCondominiumName(condominium_name) {

  }

  setCondominiumLogo(condominium_logo) {

  }

  setCondominiumColor(condominium_color) {

  }

  setCondominiumType(condominium_type) {
    this.condominium_type = condominium_type;
  }

  updateCallInfoInterface(contexto) {
    if (contexto.apartamento) {
     
      if(this.condominium_type == 'horizontal'){
        document.getElementById('apartment-label').innerHTML = "Casa";
      }else if(this.condominium_type == 'comercial'){
        document.getElementById('apartment-label').innerHTML = "Sala";
      }else if(this.condominium_type == 'vertical'){
        document.getElementById('apartment-label').innerHTML = "Apartamento";
      }
      document.getElementById('instructions').classList.add('hidden');
      document.getElementById('apartment').innerHTML = contexto.apartamento;
      document.getElementById('apartment-field').classList.add('show');
    }
    if (contexto.block_code) {
      document.getElementById('instructions').classList.add('hidden');
      document.getElementById('block').innerHTML = contexto.block_code;
      document.getElementById('block-field').classList.add('show');
    }
    
    if (contexto.morador) {
      document.getElementById('instructions').classList.add('hidden');
      document.getElementById('resident-name').innerHTML = contexto.morador;
      document.getElementById('resident-name-field').classList.add('show');
    }
    if (contexto.visitante) {
      document.getElementById('instructions').classList.add('hidden');
      document.getElementById('visitor-name').innerHTML = contexto.visitante;
      document.getElementById('visitor-name-field').classList.add('show');
    }
    if (contexto.visitorDocumentNumber) {
      document.getElementById('visitor-document').innerHTML = contexto.visitorDocumentNumber;
      document.getElementById('visitor-document-field').classList.add('show');
    }
    if (contexto.visitorAge) {
      document.getElementById('visitor-age').innerHTML = Math.round(contexto.visitorAge);
      document.getElementById('visitor-age-field').classList.add('show');
    }
    if (contexto.visitorGender) {
      if (contexto.visitorGender === 'male')
        document.getElementById('visitor-gender').innerHTML = 'Masculino';
      else document.getElementById('visitor-gender').innerHTML = 'Feminino';

      document.getElementById('visitor-gender-field');
      if (field) {
        field.style.width = '100%';
      }
    }
  }

  showError(message) {
    const error = document.querySelector('.error-warning');
    const errorMessage = document.querySelector('#error-message');

    errorMessage.innerHTML = message;
    error.classList.add('show');
  }
}
