import { Socket } from './socket';

const RTCIceConnectionState = {
  checking: 'checking',
  failed: 'failed',
  disconnected: 'disconnected',
  closed: 'closed',
  new: 'new',
  connecting: 'connecting',
  connected: 'connected',
  completed: 'completed',
};

const RTCSignalingState = {
  stable: 'stable',
  haveLocalOffer: 'have-local-offer',
  haveRemoteOffer: 'have-remote-offer',
  haveLocalPranswer: 'have-local-pranswer',
  haveRemotePranswer: 'have-remote-pranswer',
  closed: 'closed',
};

class Session {
  constructor() {
    this.pc = undefined;
    this.offer = undefined;
    this.connectedWithID = undefined;
    this.role = undefined;
    this.remoteCandidates = [];
    this.localCandidates = [];
    this.stream = undefined;
    this.initiatorAs = undefined;
  }
}

const configuration = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'stun:stun1.l.google.com:19302' },
    { urls: 'stun:stun2.l.google.com:19302' },
    { urls: 'stun:stun3.l.google.com:19302' },
    { urls: 'stun:stun4.l.google.com:19302' },
    {
      urls: 'stun:openrelay.metered.ca:80',
    },
    {
      urls: 'turn:openrelay.metered.ca:80',
      username: 'openrelayproject',
      credential: 'openrelayproject',
    },
    {
      urls: 'turn:openrelay.metered.ca:443',
      username: 'openrelayproject',
      credential: 'openrelayproject',
    },
  ],
};

export default class RTC_Call {
  constructor(voiceApi, doormanApi, animate, api) {
    this.voice = voiceApi;
    this.doorman = doormanApi;
    this._api = api;
    this.sessions = {};
    this.isCalling = false;
    this._animate = animate;

    this.offerOptions = {
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    };
    this.getMediaPermissions();

    this.callVideo = document.getElementById('call-video');
    this.callVideoContainer = document.querySelector('.call-video-container');
    this.userVideo = document.getElementById('user-video');
    this.doorManVideo = document.getElementById('door-man-video');
    this.avatar_muted = document.querySelector('.avatar-muted');
  }


  async getMediaPermissions() {
    console.log('Received local stream...');
  }

  /**
   * This function will be called to start a call and the signaling process.
   */
  async onCall() {
    if (this.isCalling) return;
    this.isCalling = true;

    console.log('Connecting to socket... ');

    this._socket = Socket.io;

    this.setSocketEventListeners();
  }

  setSocketEventListeners() {
    this._socket.on('connect', () => {
      this.roomID = this._socket.id;
      console.log('Socket connected successfully. ID: ', this.roomID);

      this._socket.emit('createRoom', {
        userID: this._socket.id,
        role: 'visitor',
      });
    });

    this._socket.on('disconnect', () => { 
      console.log('Socket disconnected!');
    });

    this._socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    if (!this.hasRTCPeerConnection()) {
      console.error("This browser doesn't support RTC Peer Connection...");
      return;
    }

    this._socket.emit('createRoom', {
      userID: this._socket.id,
      role: 'visitor',
    });

    this._socket.on('connect_failed', () => {
      console.error('Sorry, there seems to be an issue with the connection!');
    });

    this._socket.on('createOffer', async (data) => {
      console.log(
        'Request to send offer received from ' + data.requestID + '... creating a new offer.'
      );
      
      const session = await this.createPeerConnection(data, data.requestID);
      this.creatingOffer(session);
    });

    this._socket.on('offer', async (data) => {
      this.onReceiveOffer(data);
    });

    this._socket.on('onMediaStatusChanged', async (data) => {
      this.onMediaStatusChanged(data);
    });

    this._socket.on('answer', (data) => {
      console.log('Got call answer from ' + data.id);
      this.onReceiveAnswer(data);
    });

    this._socket.on('candidate', (data) => {
      console.log('Got candidate from ' + data.id);
      this.onReceiveCandidate(data);
    });

    this._socket.on('hangUp', () => {
      console.log('Hanging up...');
      document.querySelector('.start-call').classList.remove('hidden');
      document.querySelector('.end-call').classList.remove('show');
      this.onHangUp();
    });

    this._socket.on('restartIce', (data) => {
      console.log('Restart ICE candidate received for ' + data.requestID);
      const session = this.sessions[data.requestID];

      if (!session) {
        console.errr('RestartIce - No session found for ' + data.requestID);
        return;
      }
      this.restartICEConnection(session);
    });

    this._socket.on('bye', (id) => {
      this.voice.speak('CALL_ENDING');
      console.log('Session with ' + id + ' saying bye!');
    });
    this._socket.on('access_granted_by_user', (data) => {

      console.log('Access granted by user - 1');
      this.onAccessGranted();
    });
    this._socket.on('access_denied_by_user', (data) => {
      console.log('Access denied by user');
      this.onAccessDenied();
    });
    this._socket.connect();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  
  async onAccessGranted() {
    this.sleep(100);
    //this._api.getAccesses().then((accesses) => {this.doorman.accessGranted(accesses);});
    this.doorman.accessGranted();
  }

  onAccessDenied() {
    
    this.doorman.accessDenied();
    // setTimeout(() => {
    //   this.dispose();
    //   this.doorman.stopConversation();
    // }, 5000);
  }

  onMediaStatusChanged(data) {
    console.log(data);
    const session = this.sessions[data.from];
    const mediaType = data.mediaType;
    const status = data.status;

    console.log(
      'Received onMediaStatusChanged for ' +
        session.connectedWithID +
        ' ' +
        mediaType +
        ' ' +
        status
    );
    switch (mediaType) {
      case 'video':
        if (status) {
          this.callVideoContainer.classList.add('show');
        } else {
          this.callVideoContainer.classList.remove('show');
        }
        break;
      case 'mic':
        if (status) {
          this.avatar_muted.classList.add('show');
        } else {
          this.avatar_muted.classList.remove('show');
        }
        break;
    }

    switch (session.role) {
      case 'resident':
        break;
      case 'doorman':
        break;
      default:
        console.error('Received a wrong onMediaStatusChanged event!');
    }
  }

  async onReceiveCandidate(data) {
    const session = this.sessions[data.id];
    const candidate = data.candidate;

    if (session) {
      if (session.pc && session.answer) {
        console.log('Candidate from ' + data.id + 'added on successfuly candidate event...');
        this.onCandidate(session, candidate);
      } else {
        console.log(
          'Session with no pc or remote description found for candidate ',
          data.id,
          'adding it for later...'
        );
        console.log(session);
        session.remoteCandidates.push(candidate);
      }
    } else {
      console.log('Received candidate without session ' + data.id + ', creating a new one...');
      this.sessions[data.id] = { remoteCandidates: [data.candidate], connectedWithID: data.id };
    }
  }

  async onReceiveAnswer(data) {
    let session = this.sessions[data.id];

    if (session) {
      if (
        session.pc.signalingState === RTCSignalingState.stable ||
        session.pc.signalingState !== RTCSignalingState.haveLocalOffer
      ) {
        console.error(
          'Error: Already have an connected state and got a second answer. State: ' +
            session.pc.signalingState
        );
        return;
      }

      if (data.role) {
        session.role = data.role;
      }

      await session.pc.setRemoteDescription(data.answer);
      console.log('SetRemoteDescription successfuly!');
      session.answer = data.answer;

      session.remoteCandidates.forEach((candidate) => {
        console.log(
          'Adding candidate for ' + data.id + 'when received the offer and set remote description'
        );
        this.onCandidate(session, candidate);
      });
      session.remoteCandidates = [];
    } else {
      console.error('No session found for id: ', data.id);
    }
  }

  async onReceiveOffer(data) {
    console.log('Offer received from ' + data.userID + ' for room: ' + data.roomID);

    let session = this.sessions[data.userID];
    if (!session) {
      session = await this.createPeerConnection(data, data.userID);
      session.initiatorAs = 'answer';
    } else if (session.pc.signalingState === RTCSignalingState.stable) {
      console.error(
        'ERROR: Remote offer received in unexpected state: ' + session.pc.signalingState
      );
      return;
    }

    const remoteSDP = new RTCSessionDescription(data.offer.sdp);
    await session.pc.setRemoteDescription(remoteSDP);

    const answer = await session.pc.createAnswer();
    await session.pc.setLocalDescription(answer);

    this.sessions[data.userID] = session;

    session.answer = answer;

    if (session.remoteCandidates.length > 0) {
      session.remoteCandidates.forEach((candidate) => {
        console.log(
          'Adding candidate for ' + data.id + 'when received the offer and set remote description'
        );
        this.onCandidate(session.pc, candidate);
      });
      session.remoteCandidates = [];
    } else {
      console.log(
        'No remote candidates found for ' +
          data.id +
          'when received the offer and set remote description'
      );
    }

    console.log('Answer created for ' + data.userID + ' and has been sent to server...');
    this._socket.emit('answer', {
      role: 'visitor',
      type: 'answer',
      answerToID: data.userID,
      roomID: data.roomID,
      userID: this._socket.id,
      description: answer,
    });
  }

  /**
   * This function will print the ICE candidate sucess
   */
  onAddIceCandidateSuccess(session) {
    console.log('IceCandidate added for ' + session.connectedWithID + ' successfully..');
  }

  /**
   * This function will print the ICE candidate error
   */
  onAddIceCandidateError(session, error) {
    console.log(`Failed to add ICE Candidate for ${session.connectedWithI}: ${error.toString()}`);
  }

  onVideoReceived(data) {
    let event = new CustomEvent('newVideoCall');
         document.dispatchEvent(event);
  }
  setVideoStream(session, stream) {
    this.onVideoReceived();
    console.log(
      'Trying to set video stream for ' +
        session.connectedWithID +
        'on state' +
        session.pc.iceConnectionState
    );
    if (session.pc.iceConnectionState === 'connected') {
      this._animate.showVideoCall();

      if (session.role === 'doorman') {
        const container = document.querySelector('.door-man-video-container');
        container.classList.add('show');
        console.log('received remote doorman stream...');
        this.doorManVideo.srcObject = stream;
      } else {
        this.userVideo.classList.add('movedToTop');
        this.callVideo.srcObject = stream;
        console.log('received remote visitor stream...');
      }

      const endCallButton = document.querySelector('.end-call');
      endCallButton.addEventListener('click', () => {
        this.voice.speak('CALL_ENDING');
        endCallButton.classList.remove('show');
        this.onHangUp();
      });
    }
  }

  /**
   * This function will set the session remote streams
   */
  gotRemoteStream(e, session) {
    if (session.pc.iceConnectionState === 'connected') {
      this.setVideoStream(session, e.streams[0]);
    } else {
      session.stream = e.streams[0];
    }
  }

  /**
   * This function will handle the ICE state change
   */
  onIceStateChange(event, session) {
    if (session.pc) {
      console.log(
        `ICE for peer ${session.connectedWithID} -  state: ${session.pc.iceConnectionState}`
      );
      if (
        session.pc.iceConnectionState === RTCIceConnectionState.disconnected ||
        session.pc.iceConnectionState === RTCIceConnectionState.failed
      ) {
        console.warn(
          'ICE state for peer ' + session.connectedWithID + ' failed, trying to send a new offer...'
        );

        if (session.role === 'doorman') {
          const container = document.querySelector('.door-man-video-container');
          container.classList.remove('show');
        } else {
          this.userVideo.classList.remove('movedToTop');
        }

        this.restartICEConnection(session);
      }
    }

    if (session.pc.iceConnectionState === RTCIceConnectionState.connected) {
      this.setVideoStream(session, session.stream);
      session.stream = undefined;
    }
    console.log('ICE state change event: ', event);
  }

  restartICEConnection(session) {
    const options = this.offerOptions;
    options.iceRestart = true;

    if (session.initiatorAs === 'offer') {
      this.creatingOffer(session, options);
    } else if (session.initiatorAs === 'answer') {
      this.requestIceRestart(session);
    } else {
      console.error('No initiator found for ' + session.initiatorAs);
    }
  }

  /**
   * This function will ask for a new offer when renegociating ICE candidates.
   */
  requestIceRestart(session) {
    this._socket.emit('iceRestart', {
      type: 'iceRestart',
      role: 'doorman',
      roomID,
      userID,
      toID: session.connectedWithID,
    });
  }

  /**
   * This function will handle ICE candidate event.
   */
  icecandidateAdded(ev, session) {
    if (ev.candidate) {
      setTimeout(() => {
        this._socket.emit('answer', {
          type: 'candidate',
          roomID: this.roomID,
          answerToID: session.connectedWithID,
          userID: this._socket.id,
          candidate: ev.candidate,
        });
      }, 1500);
      console.log(
        'ICE candidate received for ' + session.connectedWithID + ' on room ' + this.roomID
      );
    }
  }

  /**
   * This function will check webrtc Permissions.
   */
  hasRTCPeerConnection() {
    window.RTCPeerConnection =
      window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
    window.RTCSessionDescription =
      window.RTCSessionDescription ||
      window.webkitRTCSessionDescription ||
      window.mozRTCSessionDescription;
    window.RTCIceCandidate =
      window.RTCIceCandidate || window.webkitRTCIceCandidate || window.mozRTCIceCandidate;

    return !!window.RTCPeerConnection;
  }

  /**
   * This function will handle when we got ice candidate from another user.
   */
  async onCandidate(session, candidate) {
    try {
      await session.pc.addIceCandidate(candidate);
      this.onAddIceCandidateSuccess(session);
    } catch (e) {
      this.onAddIceCandidateError(session, e);
    }
  }

  async createPeerConnection(data, id) {
    console.log('Creating peer connection...');
    let session = this.sessions[id];

    const pc = new RTCPeerConnection(configuration, { optional: [{ DtlsSrtpKeyAgreement: true }] });

    if (session) {
      session.pc = pc;
    } else {
      session = new Session();
      session.pc = pc;
      session.connectedWithID = id;
      session.remoteCandidates = [];
      this.sessions[id] = session;
    }

    if (data.role) {
      session.role = data.role;
    }

    pc.addEventListener('icecandidateerror', (event) => {
      console.error(event, event.errorText);
    });
    pc.addEventListener('iceconnectionstatechange', (e) => this.onIceStateChange(e, session));
    this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    this.stream.getTracks().forEach((track) => pc.addTrack(track, this.stream));

    pc.addEventListener('track', (e) => this.gotRemoteStream(e, session));

    pc.addEventListener('icecandidate', (e) => this.icecandidateAdded(e, session));

    return session;
  }

  /**
   * This function will create the webRTC offer request for other user.
   */
  async creatingOffer(session, options) {
    try {
      console.log('createOffer started...');
      session.offer = await session.pc.createOffer(options ? options : this.offerOptions);
      session.initiatorAs = 'offer';
      await this.onCreateOfferSuccess(session, options);
    } catch (e) {
      console.log(`Failed to create session description: ${e.toString()}`);
    }
  }

  /**
   * This function will set visitor local description of the webRTC
   */
  async onCreateOfferSuccess(session, options) {
    try {
      await session.pc.setLocalDescription(session.offer);
      this.onSetLocalSuccess(session.pc);

      if (options && options.iceRestart) {
        return;
      }

      console.log(
        'Sending offer to ' + session.connectedWithID + ' server for room ' + this.roomID
      );
      this.send('offer', {
        type: 'offer',
        role: 'visitor',
        sdp: session.offer,
        roomID: this.roomID,
        userID: this._socket.id,
        toID: session.connectedWithID,
      });
    } catch (e) {
      console.error('Setting local session description failed!', e.toString());
    }
  }

  /**
   * This function will print log of local description sucess
   */
  onSetLocalSuccess(pc) {
    console.log(`setLocalDescription complete`);
  }

  /**
   * This function will send the user message to server.
   * Sending message will be in JSON format.
   */
  send(type, message) {
    message = { ...message, roomID: this.roomID, userID: this._socket.id };
    this._socket.emit(type, JSON.stringify(message));
  }

  // A new info is dispatched when a new info is provided on the call by the visitor.
  async new_info(context) {
    console.log('new info emitido no socket');
    if (context) {
      context.rtc_id = this.roomID;
    }
    await this._api.new_doorman_call(context);

    if (context.error) {
      this._socket.emit('new_info', 'error');
    } else {
      setTimeout(() => {
        this._socket.emit('new_info', this._api._call_id);
      }, 100);
    }
  }

  contextComplete(context) {
    console.log('context_complete emitido no socket');
    this._socket.emit('context_complete', this._api._call_id);
  }

  /**
   * This function will handle when the user hangs up.
   */
  dispose() {
    console.log('Disposing session connection...', this.roomID);
    this._socket.emit('bye', { type: 'bye', roomID: this.roomID, from: this._socket.id });
    Object.values(this.sessions).forEach((session) => {
      session.pc.close();
    });

    if (this._socket) {
      this._socket.removeAllListeners();
      Socket.disconnect();
    }
  }

  /**
   * This function will be called to start a call and the signaling process.
   */
  async onHangUp() {
    console.log('Hanging up...');
    clearInterval(this.callTimeOut);
    const container = document.querySelector('.door-man-video-container');
    container.classList.remove('show');
    this._api.reset();
    const endCallButton = document.querySelector('.end-call');
    endCallButton.classList.remove('show');

    const callButtons = document.querySelector('.call-interations');
    callButtons.classList.remove('hidden');

    const userVideo = document.getElementById('user-video');
    userVideo.classList.remove('movedToTop');
    this.isCalling = false;
    this.dispose();
  }
}
