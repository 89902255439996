export default class Voice {
  constructor(clientData) {
    this.many_buildings = clientData.many_buildings;
    this.many_unities = clientData.many_unities;
    this.condominium_type = clientData.condominium_type;
    this.message = '';
    
    this.greetings = [
      this.createAudio('/audio/greeting/1.mp3'),
      this.createAudio('/audio/greeting/2.mp3'),
      this.createAudio('/audio/greeting/3.mp3'),
    ];
    this.greetings_hows_it_going = [
      this.createAudio('/audio/greeting_response/hows_it_going/1.mp3'),
    ];
    this.greetings_good_morning = [this.createAudio('/audio/greeting_response/good_morning/1.mp3')];
    this.greetings_good_afternoon = [
      this.createAudio('/audio/greeting_response/good_afternoon/1.mp3'),
    ];
    this.greetings_good_evening = [this.createAudio('/audio/greeting_response/good_evening/1.mp3')];
    this.apartment_requests = [
      this.createAudio('/audio/apartment_request/1.mp3'),
      this.createAudio('/audio/apartment_request/2.mp3'),
      this.createAudio('/audio/apartment_request/3.mp3'),
      this.createAudio('/audio/apartment_request/4.mp3'),
      this.createAudio('/audio/apartment_request/5.mp3'),
    ];
    this.block_requests = [
      this.createAudio('/audio/block_request/1.mp3'),
      this.createAudio('/audio/block_request/2.mp3'),
    ];

    this.conversation_canceled = [
      this.createAudio('/audio/cancel_conversation/1.mp3'),
      this.createAudio('/audio/cancel_conversation/2.mp3'),
      this.createAudio('/audio/cancel_conversation/3.mp3'),
    ];

    this.morador_requests = [
      this.createAudio('/audio/morador_name_request/1.mp3'),
      this.createAudio('/audio/morador_name_request/2.mp3'),
      this.createAudio('/audio/morador_name_request/3.mp3'),
      this.createAudio('/audio/morador_name_request/4.mp3'),
    ];

    this.visitor_name_requests = [
      this.createAudio('/audio/visitor_name_request/1.mp3'),
      this.createAudio('/audio/visitor_name_request/2.mp3'),
      this.createAudio('/audio/visitor_name_request/3.mp3'),
      this.createAudio('/audio/visitor_name_request/4.mp3'),
      this.createAudio('/audio/visitor_name_request/5.mp3'),
    ];

    this.did_not_understand = [
      this.createAudio('/audio/did_not_understand/1.mp3'),
      this.createAudio('/audio/did_not_understand/2.mp3'),
      this.createAudio('/audio/did_not_understand/3.mp3'),
    ];

    this.verify = [
      this.createAudio('/audio/verify/1.mp3'),
      this.createAudio('/audio/verify/2.mp3'),
      this.createAudio('/audio/verify/3.mp3'),
      this.createAudio('/audio/verify/4.mp3'),
    ];

    this.visitor_document_request = [
      this.createAudio('/audio/visitor_document_request/1.mp3'),
      this.createAudio('/audio/visitor_document_request/2.mp3'),
      this.createAudio('/audio/visitor_document_request/3.mp3'),
    ];

    this.understood = [
      this.createAudio('/audio/understood/1.mp3'),
      this.createAudio('/audio/understood/2.mp3'),
      this.createAudio('/audio/understood/3.mp3'),
    ];

    this.house_number_request = [
      this.createAudio('/audio/house_number_request/1.mp3'),
      this.createAudio('/audio/house_number_request/2.mp3'),
    ];

    this.access_granted = this.createAudio('/audio/access_granted/1.mp3');
    this.access_denied = this.createAudio('/audio/access_denied/1.mp3');

    this.ring = this.createAudio('/audio/ringing.mp3');
    this.waiting = this.createAudio('/audio/waiting.mp3', true);

    this.enter_call = this.createAudio('/audio/enter_call/enter_call.mp3');
    this.blank = this.createAudio('/audio/blank.mp3');
    this.call_ending = this.createAudio('/audio/call_ending/call_ending.mp3');
    this.invalid_document = this.createAudio('/audio/invalid_document.mp3');
  }

  createAudio(src, loop = false) {
    return (onend) => {
      return new Howl({
        src,
        loop,
        volume: 1,
        onend,
      });
    };
  }

  stop() {
    if (this.audio) {
      this.audio.pause();
    }
  }
  speak(message, onended) {
    let audio;
    if(this.message == message){
      return;
    }
    this.message = message;
    switch (message) {
      case 'UNDERSTOOD':
        var rand = Math.floor(Math.random() * this.understood.length);
        audio = this.understood[rand];
        break;
      case 'GREETING':
        var rand = Math.floor(Math.random() * this.greetings.length);
        console.log(rand);
        audio = this.greetings[rand];
        break;
      case 'GREETING_HOWS_IT_GOING':
        var rand = Math.floor(Math.random() * this.greetings_hows_it_going.length);
        audio = this.greetings_hows_it_going[rand];
        break;
      case 'GREETING_GOOD_MORNING':
        var rand = Math.floor(Math.random() * this.greetings_good_morning.length);
        audio = this.greetings_good_morning[rand];
        break;
      case 'GREETING_GOOD_EVENING':
        var rand = Math.floor(Math.random() * this.greetings_good_evening.length);
        audio = this.greetings_good_evening[rand];
        break;
      case 'GREETING_GOOD_AFTERNOON':
        var rand = Math.floor(Math.random() * this.greetings_good_afternoon.length);
        audio = this.greetings_good_afternoon[rand];
        break;

      case 'APARTMENT_REQUEST':
        
        switch (this.condominium_type) {
          case 'vertical':
            var rand = Math.floor(Math.random() * this.apartment_requests.length);
            audio = this.apartment_requests[rand];
            break;
          case 'horizontal':
            var rand = Math.floor(Math.random() * this.house_number_request.length);
            audio = this.house_number_request[rand];
            break;
        }
        break;

      case 'BLOCK_REQUEST':
        var rand = Math.floor(Math.random() * this.block_requests.length);
        audio = this.block_requests[rand];
        break;

      case 'CONVERSATION_CANCELED':
        var rand = Math.floor(Math.random() * this.conversation_canceled.length);
        audio = this.conversation_canceled[rand];
        break;

      case 'MORADOR_NAME_REQUEST':
        var rand = Math.floor(Math.random() * this.morador_requests.length);
        audio = this.morador_requests[rand];
        break;

      case 'VISITOR_NAME_REQUEST':
        var rand = Math.floor(Math.random() * this.visitor_name_requests.length);
        audio = this.visitor_name_requests[rand];
        break;
      case 'VISITOR_DOCUMENT_REQUEST':
        var rand = Math.floor(Math.random() * this.visitor_document_request.length);
        audio = this.visitor_document_request[rand];
        break;
      case 'DID_NOT_UNDERSTAND':
        var rand = Math.floor(Math.random() * this.did_not_understand.length);
        audio = this.did_not_understand[rand];
        break;
      case 'RING':
        audio = this.ring;
        break;
      case 'VERIFY':
        var rand = Math.floor(Math.random() * this.verify.length);
        audio = this.verify[rand];
        break;
      case 'CALL_ENDING':
        audio = this.call_ending;
        break;
      case 'BLANK':
          audio = this.blank;
          break;
      case 'ENTER_CALL':
        audio = this.enter_call;
        break;
      case 'ACCESS_GRANTED':
        audio = this.access_granted;
        break;
      case 'ACCESS_DENIED':
        audio = this.access_denied;
        break;
      case 'INVALID_DOCUMENT':
        audio = this.invalid_document;
        break;
      case 'WAITING':
        audio = this.waiting;
        break;
      default:
        console.error('ERROR:Voice phrase is undefined');
        break;
    }

    if (audio) {
      console.log('Playing...');
      this.audio = audio(onended);
      this.audio.play();
    }
  }
}
