export default class ContextManager {
  contexto = {};

  constructor(clientData) {
    this.many_buildings = clientData.many_buildings;
    this.many_unities = clientData.many_unities;
    this.condominium_type = clientData.condominium_type;
    this.contexto = {};
    this.contexto.visitante = '';
    this.contexto.morador = '';
    this.contexto.apartamento = '';
    this.contexto.block_code = '';
    this.contexto.intent = '';
    this.contexto.visitorQuantity = '';
    this.contexto.visitorAge = '';
    this.contexto.visitorGender = '';
    this.contexto.visitorDocumentNumber = '';
    this.contexto.visitorLastSpeech = '';
    this.contexto.givenSpeech = '';
    this.contexto.empty = true;
  }

  clearContext() {
    this.contexto = {};
    this.contexto.visitante = '';
    this.contexto.morador = '';
    this.contexto.apartamento = '';
    this.contexto.block_code = '';
    this.contexto.intent = '';
    this.contexto.visitorQuantity = '';
    this.contexto.visitorAge = '';
    this.contexto.visitorGender = '';
    this.contexto.visitorDocumentNumber = '';
    this.contexto.visitorLastSpeech = '';
    this.contexto.givenSpeech = '';
    this.contexto.empty = true;
    document.getElementsByClassName('value').innerHTML = '';
  }

  setError(error) {
    this.contexto.error = error;
    return this.contexto;
  }
  // funcao que atualiza no contexto a última pergunta feita pelo porteiro
  setGivenSpeech(givenSpeech) {
    this.contexto.givenSpeech = givenSpeech;
  }

  setVisitorQuantity(quantity) {
    this.contexto.visitorQuantity = quantity;
    return this.contexto;
  }
  setVisitorAge(age) {
    this.contexto.visitorAge = age;
    return this.contexto;
  }

  validarCPF(cpf) {	
    cpf = cpf.replace(/[^\d]+/g,'');	
    if(cpf == '') return false;	
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		
    // Valida 1o digito	
    var add = 0;	
    for (var i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      var rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)		
        rev = 0;	
      if (rev != parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (var i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    var rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	
      rev = 0;	
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    return true;   
  }

  setVisitorGender(gender) {
    this.contexto.visitorGender = gender;
    return this.contexto;
  }
  setVisitorLastSpeech(speech) {
    this.contexto.visitorLastSpeech = speech;
  }
  updateContext(data) {
    console.log(data);
    this.contexto.greetingIntent= "";
    this.contexto.empty = false;
    const { intents, entities, traits } = data;
    if (
      this.contexto.intent == 'greeting_hows_it_going' ||
      this.contexto.intent == 'greeting_good_morning' ||
      this.contexto.intent == 'greeting_good_afternoon' ||
      this.contexto.intent == 'greeting_good_evening' 
    )
      this.contexto.intent = '';
            

    if (this.contexto.givenSpeech == 'GREETING' || 
        this.contexto.givenSpeech=='GREETING_GOOD_EVENING'  ||
        this.contexto.givenSpeech=='GREETING_GOOD_MORNING'  ||
        this.contexto.givenSpeech=='GREETING_GOOD_AFTERNOON' ||
        this.contexto.givenSpeech=='GREETING_HOWS_IT_GOING') {
      
      if (traits && traits['greeting']) {
        if (traits['greeting'][0].value == 'tudo_bem') {
          this.contexto.greetingIntent = 'greeting_hows_it_going';
        }else if (traits['greeting'][0].value == 'bom_dia') {
          this.contexto.greetingIntent = 'greeting_good_morning';
        }else if (traits['greeting'][0].value == 'boa_tarde') {
          this.contexto.greetingIntent = 'greeting_good_afternoon';
        }else if (traits['greeting'][0].value == 'boa_noite') {
          this.contexto.greetingIntent = 'greeting_good_evening';
        }
      }

      if (intents.length > 0 ) {
        this.contexto.intent = intents[0].name;
      }

      if (entities['wit$number:houseNumber'] ) {
        
        this.contexto.apartamento = entities['wit$number:houseNumber'][0].value;
      }

      if (entities['wit$contact:morador'] ) {
        
        this.contexto.morador = entities['wit$contact:morador'][0].value;
      }

      if (entities['wit$contact:visitor'] && this.contexto.visitante == '') {
        
        this.contexto.visitante = entities['wit$contact:visitorName'][0].value;
      }
      if (entities['block:block']) {
        console.log("situação 1");
        if(this.many_buildings){
          this.contexto.block_code = entities['block:block'][0].value;
          console.log("many buildings");
        }
        else{
            // this.contexto.apartamento = this.contexto.apartamento+entities['block:block'][0].value;
            // console.log("one building");
            // console.log(this.contexto.apartamento);
        }
          
      }
    } else {
      if (this.contexto.givenSpeech == 'MORADOR_NAME_REQUEST') {
        if (entities['block:block']) {
          console.log("situação 2");
          if(this.many_buildings)
          this.contexto.block_code = entities['block:block'][0].value;
          else
          this.contexto.apartamento = this.contexto.apartamento+this.contexto.block_code;
        }
        if (entities['wit$number:houseNumber'] != undefined) {
          this.contexto.apartamento = entities['wit$number:houseNumber'][0].value;
        }else if (entities['wit$contact:contact']) {
          this.contexto.morador = entities['wit$contact:contact'][0].value;
        } else if (entities['wit$contact:morador']) {
          this.contexto.morador = entities['wit$contact:morador'][0].value;
        }  else {
          this.contexto.givenSpeech = '';
        }
      } else if (this.contexto.givenSpeech == 'VISITOR_NAME_REQUEST') {
        if (entities['block:block']) {
          console.log("situação 3");
          if(this.many_buildings)
          this.contexto.block_code = entities['block:block'][0].value;
          else
          this.contexto.apartamento = this.contexto.apartamento+this.contexto.block_code;
        }
        if (entities['wit$number:houseNumber'] != undefined) {
          this.contexto.apartamento = entities['wit$number:houseNumber'][0].value;
        }else if (entities['wit$contact:contact']) {
          this.contexto.visitante = entities['wit$contact:contact'][0].value;
        } else if (entities['wit$contact:visitorName']) {
          this.contexto.visitante = entities['wit$contact:visitorName'][0].value;
        }if (entities['wit$contact:morador'] ) {
          this.contexto.morador = entities['wit$contact:morador'][0].value;
        } else {
          this.contexto.givenSpeech = '';
        }
      } else if (this.contexto.givenSpeech == 'APARTMENT_REQUEST') {
        if (entities['block:block']) {
          if(this.many_buildings)
          this.contexto.block_code = entities['block:block'][0].value;
          else
          this.contexto.apartamento = this.contexto.apartamento+this.contexto.block_code;
        }
        if (entities['wit$contact:morador']) {
        this.contexto.morador = entities['wit$contact:morador'][0].value;
        } 
        else if (entities['wit$number:houseNumber'] != undefined) {
          this.contexto.apartamento = entities['wit$number:houseNumber'][0].value;
        } else if (entities['wit$number:number'] != undefined) {
          this.contexto.apartamento = entities['wit$number:number'][0].value;
        } if (entities['wit$contact:morador'] ) {
          this.contexto.morador = entities['wit$contact:morador'][0].value;
        } else {
          this.contexto.givenSpeech = '';
        }
      }  else if (this.contexto.givenSpeech == 'BLOCK_REQUEST') {
        if (entities['block:block']) {
          
          this.contexto.block_code = entities['block:block'][0].value;
        }
        if (entities['wit$contact:morador']) {
        this.contexto.morador = entities['wit$contact:morador'][0].value;
        } 
        else if (entities['wit$number:houseNumber'] != undefined) {
          this.contexto.apartamento = entities['wit$number:houseNumber'][0].value;
        } else if (entities['wit$number:number'] != undefined) {
          this.contexto.apartamento = entities['wit$number:number'][0].value;
        } if (entities['wit$contact:morador'] ) {
          this.contexto.morador = entities['wit$contact:morador'][0].value;
        } else {
          this.contexto.givenSpeech = '';
        }
      }
      
      else if (this.contexto.givenSpeech == 'VISITOR_DOCUMENT_REQUEST') {
      //   console.log(this.contexto.givenSpeech);
      //   var raw = data.text;
      //   console.log(raw);
      //   var numbers = raw.replace(/\D/g, '');
      //   console.log(numbers);
      //   console.log(parseInt(numbers));
      //   console.log(this.validarCPF(numbers));
      //   
      // //   if(parseInt(numbers) > 0 && this.validarCPF(numbers)){
         
      // // }else{
      // //     this.contexto.document_status= 'visitor_document_invalid';
          this.contexto.givenSpeech = '';
          this.contexto.visitorDocumentNumber = data.text;
          
      
    }
  }
    return this.contexto;
  }
}
