import axios from 'axios';

var config = require('../../conf/config.json');

const KeysetApi = axios.create({
  baseURL: config.apiUrl,
});

KeysetApi.defaults.headers.authorization = `Bearer ${config.token}`;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

export default class Api {
  constructor() {
    this._call_id = '';
    this.new_call = true;
    this.errors=[];
  }

  reset(){
    this._call_id = '';
    this.new_call = true;
    this.errors=[];
  }

  async new_doorman_call(context) {
    console.log('newcallapi');
    context.apiKey = localStorage.apiKey;
    context.new_call = this.new_call;
    context.call_id = this._call_id;
    var ret = await KeysetApi.post('doorman', context);
    console.log(ret);
    if(!ret.data.errors || ret.data.errors=='[]'){
       
      this.new_call = false;
      this._call_id = ret.data.id;
      console.log(this._call_id);
      console.log(this.new_call);
    }else{
      this.errors = JSON.parse(ret.data.errors);
    }
    return ret.data;
  }

  async submitReport(topic,message,contactInfo){
  //funcao para envio do report de erro ou sugestao
    
  }

  async getClientData() {
    var apiKey = localStorage.apiKey;
    let geolocation = await this.getGeolocation();
    let latitude = geolocation.coords.latitude;
    let longitude = geolocation.coords.longitude;
    var ret = await KeysetApi.post('doorman/client/data', { params: {apiKey,latitude,longitude} });
    return ret.data;
  }

  // async getAccesses() {
  //   var apiKey = localStorage.apiKey;
  //   var ret = await KeysetApi.get('doorman/getAccesses/'+this._call_id, { params: { apiKey } });
  //   return ret.data;
  // }

  // async openAccess(access) {
  //   var apiKey = localStorage.apiKey;
  //   var ret = await KeysetApi.get('doorman/openAccess/'+this._call_id+'/'+access.id, { params: { apiKey } });
  //   return ret.data;
  // }

  async getGeolocation() {
    console.log('getGeolocation');
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
    );
}
}
