var config = require('../../conf/config.json');
console.log(config);
export class Socket {
  //static socketEndPoint = 'localhost:8020';
  static socketEndPoint = config.socketURL;

  static _io;

  static get io() {
    if (!Socket._io) {
      Socket._io = io(Socket.socketEndPoint, {
        autoConnect: false,
        forceNew: true,
        upgrade:false,
        transports: ['websocket',  'polling'],
      });
    }
    return Socket._io;
  }

  static disconnect() {
    if (Socket._io) {
      Socket._io.disconnect();
      Socket._io = undefined;
    }
  }
}
