import SpeechApi from './SpeechApi';
import DoorMan from './doorMan.js';

var doorman = new DoorMan();

window.addEventListener('DOMContentLoaded', async () => {
  await doorman.initialize();
    document.getElementById('start-via-voice').addEventListener('click', () => {
      doorman.startViaVoice();
    });

    document.getElementById('start-via-text').addEventListener('click', () => {
      doorman.startViaText();
    });

    document.getElementById('type-info').addEventListener('click', () => {
      doorman.end_call();
    });
   
  
});
