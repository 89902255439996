import { Socket } from './socket.js';

export default class SpeechTranscription {
  constructor(stream) {
    this.pids = [...document.querySelectorAll('.pid')];
    this.stream = stream;
    this.text = '';
    //this.btnTranscription = document.getElementById('button-record');
    this.transcription = '';
    this._isRecording = false;
    this.dialogue = false;
    this.bufferSize = 2048;
    this.count = 0;
    this.speechContext = undefined;
    this.initialTime = Date.now();
    var start = new Date().getTime();

    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > 1000) {
        break;
      }
    }

    this.init();
  }

  get isRecording() {
    return this._isRecording;
  }

  set isRecording(v) {
    if (this._isRecording === v) return;
    this._isRecording = v;

    if (v) {
      console.log("started recording");
      this.stream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
      this.audioCtx.resume();
      this.processor.connect(this.audioCtx.destination);
      this.microphone.connect(this.processor);
    }

    if (!v) {
      console.log("stopped recording");
      this.stream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
      this.processor.disconnect(this.audioCtx.destination);
      this.microphone.disconnect(this.processor);
      this.audioCtx.suspend();
    }
  }

  async init() {
    this.stream.getAudioTracks().forEach((track) => {
      track.enabled = false;
    });
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioCtx = new AudioContext();
    const analyser = this.audioCtx.createAnalyser();
    this.processor = this.audioCtx.createScriptProcessor(this.bufferSize, 1, 1);
    this.microphone = this.audioCtx.createMediaStreamSource(this.stream);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    this.microphone.connect(analyser);
    analyser.connect(this.processor);

    this.processor.onaudioprocess = (e) => {
      this.microphoneProcess(e);
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      this.colorPids(Math.max(0, average));
    };
  }

  colorPids(vol) {
    const numberOfPidsToColor = Math.round((vol - 25) / 3);
    const pidsToColor = this.pids.slice(0, numberOfPidsToColor);
    for (const pid of this.pids) {
      pid.style.backgroundColor = '#e6e7e8';
    }
    for (const pid of pidsToColor) {
      pid.style.backgroundColor = '#3957bd';
    }
  }

  emitEmptyMessage() {
    this.isRecording = false;
    let event = new CustomEvent('emptyMessage');
    document.dispatchEvent(event);
  }
  microphoneProcess(e) {
    const left = e.inputBuffer.getChannelData(0);
    const left16 = SpeechTranscription.downsampleBuffer(left, 44100, 16000);
    if (this.isRecording) {
      if (Date.now() - this.initialTime > 10000) {
        console.log('usuario nao falou nada');
        this.emitEmptyMessage();
        return;
      }
      console.log('.');
      this.socket.emit('stream', {
        audio: left16,
        speechContext: [this.speechContext],
      });
    }
  }

  stop() {
    console.log('transcrição parada!');
    this.isRecording = false;
  }

  cancel() {
    console.log('transcrição cancelada!');
    this.isRecording = false;
    this.stream.getTracks().forEach((track) => {
      if (track.readyState == 'live' && track.kind === 'audio') {
          track.stop();
      }
  });
  }

  startSocket() {
    this.socket = Socket.io;

    if (!this.socket.hasListeners('emptyMessage')) {
      this.socket.on('emptyMessage', () => {
        console.log('emptyMessage');
        this.emitEmptyMessage();
      });
    }

    if (!this.socket.hasListeners('speech')) {
      this.socket.on('speech', (intent) => {
        if (
          intent.intents.length == 0 &&
          Object.keys(intent.entities).length == 0 &&
          Object.keys(intent.traits).length == 0
        ) {
          this.emitEmptyMessage();
          return;
        }
        let event = new CustomEvent('newContext', { detail: intent });
        document.dispatchEvent(event);
      });
    }
  }

  start(speechContext = undefined) {
    this.audioCtx.resume();
    this.initialTime = Date.now();
    this.text = '';
    console.log('Começou a transcrição...');
    this.isRecording = true;
    this.transcription = '';
    this.speechContext = speechContext
      ? [
          {
            phrases: [speechContext],
          },
        ]
      : undefined;
  }

  /**
   * Downsamples a given audio buffer from sampleRate to outSampleRate.
   * @param {Array} buffer The audio buffer to downsample.
   * @param {number} sampleRate The original sample rate.
   * @param {number} outSampleRate The new sample rate.
   * @return {Array} The downsampled audio buffer.
   */
  static downsampleBuffer(buffer, sampleRate, outSampleRate) {
    if (outSampleRate === sampleRate) {
      return buffer;
    }
    if (outSampleRate > sampleRate) {
      throw new Error('Downsampling rate show be smaller than original sample rate');
    }
    const sampleRateRatio = sampleRate / outSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Int16Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;
    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      let accum = 0;
      let count = 0;
      for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }

      result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }
    return result.buffer;
  }
}
