import Voice from './voice.js'; //voz
import Api from './api.js';
import SpeechTranscription from './speechTranscriptor.js'; //ouvido
import ContextManager from './contextManager.js'; //raciocinio verbal/contextual
import RTC from './RTC.js'; //comunicacao em tempo real
import Animate from './animation.js';
import FaceDetect from './facedetection.js';


export default class DoorMan {
  constructor() {
    this._OS = this.getMobileOperatingSystem();
    this._api = new Api();
    this._animate = new Animate(this._OS);
    this._faceDetector = new FaceDetect();
   

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams.get('id'));
    if (urlParams.get('id') == null) {
      console.error('id do acesso da portaria não informado');
      this._animate.showError(
        'QRCode Inválido - erro 01'
      );
    } else {
      localStorage.setItem('apiKey', urlParams.get('id'));
    }

    
    this._isSpeaking = false;
    this._onGoingConversation = false;
    this._webcamSnapshot;
    this._understandAtempts = 0;
    this._maxUnderstandingAtempts = 3;
    
  }
  async initialize() {
  if(this._OS == 'Android'){
   var location_permission =  await this.requestLocationPermissionAndroid();
   console.log(location_permission);
   if(location_permission == 'granted'){
    this.updateClientData().then(() => {
      this._animate.hideGeolocationPrompt();
      this._animate.hideLocationInstructions();
      this._animate.showMainForm();
    });
   }else if(location_permission == 'denied'){
    this._animate.showLocationPermissionDenied();
    return;
   }
  } else if(this._OS == 'iOS'){
    document.getElementById('geolocation-instructions').classList.add('show');
    document.getElementById('location-instructions-accept').addEventListener('click', () => {
      this._animate.hideLocationInstructions();
      this._animate.showGeolocationPrompt();
      navigator.geolocation.getCurrentPosition((position) => {
        this.updateClientData().then(() => {
          this._animate.hideGeolocationPrompt();
          this._animate.showMainForm();
        });
      }
      , (error) => {
        this._animate.showLocationPermissionDenied();
      });
    });

}
 
  

    /*
    Nova mensagem transcrita do usuario, essa funcao chama o Wit.AI
    para extrair as entidades
    */

    //A transcrição falhou
    document.addEventListener('emptyMessage', (e) => {
      console.log('emptymessage');
      if (this._onGoingConversation) {
        this._transcriptor.isRecording = false;
        this._transcriptor.initialTime = Date.now();
        this._voice.speak('DID_NOT_UNDERSTAND', () => {
          this._transcriptor.start();
          if (this._understandAtempts >= this._maxUnderstandingAtempts) {
            //TODO: fluxo de chamada nao entendida, verificar se out_of_context
            var contexto = this._contextManager.setError('did_not_understand');
            this._rtc_interface.new_info(contexto);
            console.log('nao entendido transferindo ligacao');
            console.log(this.contexto);
          }
          this._understandAtempts++;
        });
      }
    });
    /*
        Wit AI respondeu com as entidades contidas na fala do usuário,
        essa funcao chama a atualizacao do contexto da conversa

        */
    document.addEventListener('newContext', (e) => {
      var contexto = this._contextManager.updateContext(e.detail);
      console.log(contexto);
      if (this._onGoingConversation) {
        this.respond(contexto);
        this._animate.updateCallInfoInterface(contexto);
      }
      this._rtc_interface.new_info(contexto);
    });

    document.addEventListener('newVideoCall', (e) => {
     this._voice.stop();
     this.stopConversation;
     this._animate.showQuestion('');
    });

  
     
  }

  async startUserVideo(){
    try{
      var stream = await navigator.mediaDevices
      .getUserMedia({
        video: true,
        // audio: true,
      },);
        
      }catch(e){
        console.log("erro:"+e);
        if(e=="NotAllowedError: Permission denied"){
          console.error("erro de permissao de camera");
          //TODO: mostrar state de erro de permissao de camera
        }
        return;
      }
  
      const userVideo = document.getElementById('user-video');
      userVideo.srcObject = stream;
      document.getElementById("camera-prompt-android").classList.remove('show');
      document.getElementById("camera-prompt-ios").classList.remove('show');
  }

  async updateClientData() {
    var client_data = await this._api.getClientData();
    this._voice = new Voice(client_data);
    this._rtc_interface = new RTC(this._voice, this, this._animate, this._api);
    this._contextManager = new ContextManager(client_data);
    this._animate.setCondominiumType(client_data.condominium_type);
    this._animate.setCondominiumName(client_data.client_name);
    this._animate.setCondominiumLogo (client_data.client_avatar);
    this._animate.setCondominiumColor(client_data.client_background_color);
    this.hasManyBlocks = client_data.many_buildings;
    this.hasManyUnities = client_data.many_unities;
    this.condominium_type = client_data.condominium_type;
  }
  async requestCameraPermission() {
    if(this._OS == 'Android'){
      await navigator.permissions.query({name:"camera"}).then(function(result) {
        if (result.state == "granted") {
          console.log('camera ok');
        } else if (result.state == "prompt") {
          console.log('camera prompt');
          document.getElementById('camera-prompt-android').classList.add('show');
        } else if (result.state == "denied") {
          console.log('microphone denied');
          error.classList.add('show');
        }
        result.onchange = function() {
          console.log(result.state);
          document.getElementById('camera-prompt-android').classList.remove('show');
        }
      });
      
    }else if(this._OS == 'iOS'){
      document.getElementById('camera-prompt-ios').classList.add('show');
      return;
    }
  }

  async requestMicrophonePermission() {
    if(this._OS == 'Android'){
      await navigator.permissions.query({name:"microphone"}).then(function(result) {
        if (result.state == "granted") {
          console.log('mic ok');
        } else if (result.state == "prompt") {
          console.log('mic prompt');
          document.getElementById('microphone-prompt-android').classList.add('show');
        } else if (result.state == "denied") {
          console.log('microphone denied');
          error.classList.add('show');
        }
        result.onchange = function() {
          console.log(result.state);
          document.getElementById('microphone-prompt-android').classList.remove('show');
        }
      });
      
    }else if(this._OS == 'iOS'){
      document.getElementById('microphone-prompt-ios').classList.add('show');
      return;
    }
  }

  async requestLocationPermissionAndroid() {
    var result = await navigator.permissions.query({name:"geolocation"});
    return new Promise((resolve, reject) =>{
      if (result.state == "granted") {
        console.log('location ok');
        resolve("granted")
      } else if (result.state == "prompt") {
        console.log('location prompt');
        this._animate.showLocationInstructions();
        if(this._OS == 'iOS'){
          console.log('prompt-ios');
          resolve("promptIOS");
        }

      } else if (result.state == "denied") {
        console.log('location denied');
        document.getElementById('geolocation-error').classList.add('show');
        resolve("denied");
      }
      result.onchange = function() {
        console.log(result.state);
        if(result.state == "granted"){
          document.getElementById('geolocation-prompt-android').classList.remove('show');
          document.getElementById('geolocation-prompt-ios').classList.remove('show');
          console.log("changed to granted")
          resolve("granted")
        }else if(result.state == "denied"){
          document.getElementById('geolocation-error').classList.add('show');
          resolve("changed to denied");
        }
        
      }

    })
  }



  async startConversation() {

    await this.requestCameraPermission();

    await this.startUserVideo();
    document.getElementById('button-record').addEventListener('click', () => {
    this.end_call();
    });   

    this._animate.showQuestion('');
    this._onGoingConversation = true;
    this._voice.speak('BLANK', async () => {
      await this.requestMicrophonePermission();
      try {
        var stream = await navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: 'default',
          sampleRate: 16000,
          sampleSize: 16,
          channelCount: 1,
        },
      });
      } catch (e) {
        console.log("erro:"+e);
        if(e=="NotAllowedError: Permission denied"){
          console.error("erro de permissao de microfone");
          //TODO: mostrar state de erro de permissao de microfone
      }
      return;
    }
      if(this._OS == 'iOS'){
        document.getElementById('microphone-prompt-ios').classList.remove('show');
      }
      this._animate.showFaceFrame();
     
      this._animate.startConversation();
      document.getElementById('user-info').style.display = 'block';
      
      this._transcriptor = new SpeechTranscription(stream);
      setTimeout(async() => {
        this._animate.showShutter();
        console.log("começa a foto");
        await this._faceDetector.takeSnapshot();
        setTimeout(() => {
          console.log("termina a foto");
           this._animate.hideShutter();
           setTimeout(() => {
            this._animate.hideFaceFrame();
           }, 500);
        }, 1000);
        
      }, 1000);
      
    this._voice.speak('RING', async () => {
     
      
      this._animate.showInstructions();
     
      this._transcriptor.startSocket();
      
      this._animate.startCollectingData();
      this._webcamSnapshot = this._faceDetector.snapshot;
      this._animate.showQuestion('Como posso te ajudar?');
      this._contextManager.setGivenSpeech('GREETING');
      this._voice.speak('GREETING', () => {
        this._transcriptor.start();
      });
    });
  });
  }

  backToForm() {
    console.log('back to form');
  }

  /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

  async useKeyboard() {
    await this._faceDetector.takeSnapshot();
    await this._rtc_interface.onCall();
    this._animate.startForm();
  }



  end_call() {
    this._rtc_interface.new_call = true;
    console.log('button pressed-stop');

    this.stopConversation();
    if(this._transcriptor)
    this._transcriptor.cancel();
    this.onStopConversation();
    this._rtc_interface.onHangUp();
    this._animate.showMainForm();
  }

  async submitForm() {
    var visit_intent = document.querySelector('input[name="pourpose"]:checked').value;
    var resident_name = document.getElementById('resident-name-input').value;
    // var apartment_number = document.getElementById('apartment-number-input').value;
    var visitor_name = document.getElementById('visitor-name-input').value;
    // var visitor_document= document.getElementById('visitor-document-input').value;


    this._webcamSnapshot = this._faceDetector.snapshot;
    var contexto ={
      intent: visit_intent,
      morador:resident_name,
      visitante: visitor_name,
      snapshot: this._webcamSnapshot,
    }
    this._animate.updateCallInfoInterface(contexto);
    this._animate.showQuestion('Localizando morador...');
    this._animate.showLoading();
    this._onGoingConversation = true;
   
    await this._rtc_interface.new_info(contexto);
    console.log(this._rtc_interface._api.errors.length)
   
    if (this._rtc_interface._api.errors.length > 0) {
      if(this.using_method == 'voice'){
        this._animate.showQuestion('Morador não encontrado');
        this._animate.resetFormValidation();
        this._animate.hideLoading();
        setTimeout(() => {
          this.end_call();
        }, 4000);
      }else{
        this._animate.resetFormValidation();
        this._animate.hideLoading();
        this._animate.showMainForm();
        this._animate.showResidentNotFoundError();
        this.end_call();
      }
      
    } else {
      this._animate.showQuestion('Aguarde um momento, informando o morador');
      this._animate.resetFormValidation();
      this._voice.speak('WAITING', () => {});
    }
  }

  async startViaText() {
    this._animate.resetFormValidation();

    console.log('start-via-text');
    var visitorName = document.getElementById('visitor-name-input').value;
    var residentName = document.getElementById('resident-name-input').value;
    var visitIntent = document.querySelector('input[name="pourpose"]:checked').value;
    var error = false;
    if (visitorName == '') {
      this._animate.showMissingVisitorNameError();
      error = true;
    }
    if (residentName == '') {
      this._animate.showMissingResidentNameError();
      error = true;
    }
    if(error){
      return;
    }

    this._animate.hideMainForm();
    this._rtc_interface.onCall();

    await this.requestCameraPermission();

    await this.startUserVideo();
    document.getElementById('button-record').addEventListener('click', () => {
    this.end_call();
    });   

    this._animate.showQuestion('');
    this._onGoingConversation = true;
    this._voice.speak('BLANK', async () => {
      await this.requestMicrophonePermission();
      try {
        var stream = await navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: 'default',
          sampleRate: 16000,
          sampleSize: 16,
          channelCount: 1,
        },
      });
      } catch (e) {
        console.log("erro:"+e);
        if(e=="NotAllowedError: Permission denied"){
          console.error("erro de permissao de microfone");
          //TODO: mostrar state de erro de permissao de microfone
      }
      return;
    }
      if(this._OS == 'iOS'){
        document.getElementById('microphone-prompt-ios').classList.remove('show');
      }
      this._animate.showFaceFrame();
      setTimeout(async() => {
        this._animate.showShutter();
        console.log("começa a foto");
        await this._faceDetector.takeSnapshot();
        setTimeout(() => {
          console.log("termina a foto");
           this._animate.hideShutter();
           setTimeout(() => {
            this._animate.hideFaceFrame();
            
            this.submitForm();
            
           }, 500);
        }, 1000);
        
      }, 1000);


    });
}

  startViaVoice() {

      console.log('button pressed-start');
      this._animate.hideMainForm();
      this._rtc_interface.onCall();
      this.startConversation();
      

  }

  onStopConversation() {
    this._voice.stop();
    this._voice.speak('CALL_ENDING', () => {});
  }

  



  accessGranted() {
    
    // window.api = this._api;
    // window.animate = this._animate;
    // window.accesses = accesses;
    // window.accesses_length = accesses.length;
    // window.current_access_index = 0;

    // window.openAccessKey = async function (access){
      
    //   window.api.openAccess(access);
    //   await window.animate.showOpenLoading();
    //   window.accesses.shift();
    //   window.animate.hideOpenLoading();
      
    //   if(window.accesses.length>0){
    //     window.current_access_index++;
    //     window.animate.updateAccessesCounter();
    //     window.animate.showKey(window.accesses);
    //   }else{
       
    //   }
    // }
   

    this._voice.stop();
    this._animate.showAccessGranted();
    this._voice.speak('ACCESS_GRANTED', () => {});
  }

  accessDenied() {
    this._voice.stop();
    this._animate.showAccessDenied();
    this._voice.speak('ACCESS_DENIED', () => {});
  }

  stopConversation() {
    console.log('stop conversation');
    this._voice.stop();
    this._onGoingConversation = false;
    if(this._transcriptor){
      this._transcriptor.stop();
    }
    this._api.reset();
    this._contextManager.clearContext();
    this._animate.stopConversation();
  }


  respond(contexto) {
    this._animate.hidePids();
    this._transcriptor.isRecording = false;
    this._transcriptor.initialTime = Date.now();
    contexto.snapshot = this._webcamSnapshot;
    console.log(contexto.intent);
    if (contexto.intent != 'cancelConversation') {
      if (contexto.document_status == 'visitor_document_invalid') {
        this._voice.speak('INVALID_DOCUMENT', () => {
          this._transcriptor.start();
        });
        return;
      }
      if (contexto.intent == 'UserDidNotUnderstand') {
        console.log(this._contextManager.contexto.givenSpeech);
        this._voice.speak(this._contextManager.contexto.givenSpeech, () => {
          contexto.intent = '';
          this._transcriptor.start();
        });
        return;
      }
      if (contexto.greetingIntent == 'greeting') {
        this._animate.showQuestion('Como posso te ajudar?');
        this._contextManager.setGivenSpeech('GREETING');
        this._voice.speak('GREETING', () => {
          this._transcriptor.start();
          //
        });
        return;
      }
      if (contexto.greetingIntent == 'greeting_good_morning') {
        this._animate.showQuestion('Bom dia, Como posso te ajudar?');
        this._contextManager.setGivenSpeech('GREETING_GOOD_MORNING');
        this._voice.speak('GREETING_GOOD_MORNING', () => {
          this._transcriptor.start();
          //
        });
        return;
      }
      if (contexto.greetingIntent == 'greeting_hows_it_going') {
        this._animate.showQuestion('Como posso te ajudar?');
        this._contextManager.setGivenSpeech('GREETING_HOWS_IT_GOING');
        this._voice.speak('GREETING_HOWS_IT_GOING', () => {
          this._transcriptor.start();
          //
        });
        return;
      }
      if (contexto.greetingIntent == 'greeting_good_afternoon') {
        this._animate.showQuestion('Boa tarde! Como posso te ajudar?');
        this._contextManager.setGivenSpeech('GREETING_GOOD_AFTERNOON');
        this._voice.speak('GREETING_GOOD_AFTERNOON', () => {
          this._transcriptor.start();
          //
        });
        return;
      }
      if (contexto.greetingIntent == 'greeting_good_evening') {
        this._animate.showQuestion('Boa noite! Como posso te ajudar?');
        this._contextManager.setGivenSpeech('GREETING_GOOD_EVENING');
        this._voice.speak('GREETING_GOOD_EVENING', () => {
          this._transcriptor.start();
          //
        });
        return;
      } else if (contexto.morador == '') {
        this._animate.showQuestion('Qual o nome da pessoa que você está procurando?');
        this._contextManager.setGivenSpeech('MORADOR_NAME_REQUEST');
        //
        this._voice.speak('UNDERSTOOD', () => {
          this._voice.speak('MORADOR_NAME_REQUEST', () => {
            this._transcriptor.start();
            //
          });
        });

        return;
      } else if (contexto.apartamento == '' && this.hasManyUnities) {
        this._animate.showQuestion('REQUEST_APARTMENT_NUMBER');
        this._contextManager.setGivenSpeech('APARTMENT_REQUEST');
        //
        this._voice.speak('UNDERSTOOD', () => {
          this._voice.speak('APARTMENT_REQUEST', () => {
            this._transcriptor.start();
            //
          });
        });

        return;
      }else if (contexto.block_code== '' && this.hasManyBlocks) {
          this._animate.showQuestion('Qual o bloco?');
          this._contextManager.setGivenSpeech('BLOCK_REQUEST');
          //
          this._voice.speak('UNDERSTOOD', () => {
            this._voice.speak('BLOCK_REQUEST', () => {
              this._transcriptor.start();
              //
            });
          });
  
          return;
      } else if (contexto.visitante == '') {
        this._animate.showQuestion('Qual o seu nome?');
        this._contextManager.setGivenSpeech('VISITOR_NAME_REQUEST');
        //
        this._voice.speak('UNDERSTOOD', () => {
          this._voice.speak('VISITOR_NAME_REQUEST', () => {
            this._transcriptor.start();
            //
          });
        });

        return;
      } else if (contexto.visitorDocumentNumber == '' && this.condominium_type != 'house') {
        console.log('pedindo cpf');
        this._animate.showQuestion('Informe o seu CPF:');
        this._contextManager.setGivenSpeech('VISITOR_DOCUMENT_REQUEST');
        this._voice.speak('UNDERSTOOD', () => {
          this._voice.speak('VISITOR_DOCUMENT_REQUEST', () => {
            this._transcriptor.start('$OOV_CLASS_DIGIT_SEQUENCE');
          });
        });

        return;
      } else {
        this._rtc_interface.contextComplete(contexto);

        //contexto completo, checar a existencia dos dados informados
        this._animate.showQuestion('Localizando morador...');

        this._voice.speak('VERIFY', () => {
          console.log(this._rtc_interface._api.errors.length);
          console.log(this._rtc_interface._api.errors.length > 0);
          if (this._rtc_interface._api.errors.length > 0) {
            this._animate.showQuestion('Morador não encontrado');
            this._voice.speak('ERROR', () => {
              this._transcriptor.start();
            });
          } else {
            this._animate.showLoading();
            this._animate.showQuestion('Aguarde um momento, estou informando o morador');
            this._voice.speak('WAITING', () => {});
          }
        });
      }
    } else {
      this._voice.speak('CONVERSATION_CANCELED', () => {
        this.end_call();
      });
    }
  }
}
