export default class FaceDetect {
  constructor() {
    this.video = document.getElementById('user-video');
    this.canvas = document.getElementById('snapshot');
    this._intervalId = '';
    this.context = this.canvas.getContext('2d');
    this._stream = {};
    this.init();
    this.snapshot = '';
  }

  async init() {
    // this._stream = await navigator.mediaDevices.getUserMedia({
    //   video: true,
    // });
    // this.video.srcObject = this._stream;
    // this.video.muted = true;
    //para teste local
    //const faceModelsUrl = '../../src/scripts/facemodels';
    //para producao
    // const faceModelsUrl = './facemodels';
    // await faceapi.nets.tinyFaceDetector.loadFromUri(faceModelsUrl),
    // await faceapi.nets.faceLandmark68Net.loadFromUri(faceModelsUrl),
    // await faceapi.nets.faceRecognitionNet.loadFromUri(faceModelsUrl),
    // await faceapi.nets.ageGenderNet.loadFromUri(faceModelsUrl);
    //await faceapi.nets.tinyFaceDetector.loadFromUri(faceModelsUrl),
    //await faceapi.nets.faceLandmark68Net.loadFromUri(faceModelsUrl),
    //await faceapi.nets.faceRecognitionNet.loadFromUri(faceModelsUrl),
    //await faceapi.nets.ageGenderNet.loadFromUri(faceModelsUrl)
  }
  async start() {
    console.log('facedetector');
    // this._intervalId = setInterval(async () => {
    //   const detections = await faceapi
    //     .detectAllFaces(this.video, new faceapi.TinyFaceDetectorOptions())
    //     .withFaceLandmarks() // detect landmark
    //     .withFaceDescriptors() // detect descriptor around face
    //     .withAgeAndGender();

    //   // ### Input in to console result's detection
    //   if (detections.length > 0) {
    //     let event = new CustomEvent('newFaceDetection', { detail: detections });
    //     document.dispatchEvent(event);
    //     this.takeSnapshot();
    //   }

    //   // ### Resize media elements
    //   this._dims = faceapi.matchDimensions(this.VideoCanvas, this.video, false);
    //   const resizedDetections = faceapi.resizeResults(
    //       detections,
    //       this._dims
    //   );
    //   // ### Clear before picture
    //   this.VideoCanvas
    //     .getContext('2d')
    //     .clearRect(0, 0, this.VideoCanvas.width, this.VideoCanvas.height)

    //   // ### Drawing  in to VideoCanvas
    //   faceapi.draw.drawDetections(this.VideoCanvas, resizedDetections);
    // }, 100);
  }

  stop() {
    clearInterval(this._intervalId);
    //this._stream.getTracks().forEach(function(track) { track.stop(); });
  }

  async takeSnapshot() {
    console.log('takesnapshot');
    this.canvas.width = this.video.videoWidth;
    this.canvas.height = this.video.videoHeight;

    await this.context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
    var dataURL = await this.canvas.toDataURL();
    // console.log(dataURL);
    this.snapshot = dataURL;
    return dataURL;
  }
}
